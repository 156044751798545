export function sanitizeCSS(cssString) {
  const element = document.createElement('div');

  return cssString
    .split('}')
    .map(block => {
      const [selector, rules] = block.split('{').map(part => part.trim());
      if (!selector || !rules) return '';

      const sanitizedRules = rules
        .split(';')
        .map(rule => {
          let [property, value] = rule.split(':').map(item => item.trim());
          if (!property) return '';

          
          if (property.startsWith('--') || value.includes('var(')) {
            return `${property}: ${value}`;
          }

          if (!(property in element.style)) return '';
          element.style[property] = value;

          return element.style[property] ? `${property}: ${value}` : '';
        })
        .filter(Boolean)
        .join('; ');

      return sanitizedRules ? `${selector} { ${sanitizedRules} }` : '';
    })
    .filter(Boolean)
    .join(' ');
}
