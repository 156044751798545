import { useEffect, useRef } from "react";
import { Helmet } from "react-helmet";

export const ApplePayButton = ({
  config = {},
  token,
  vTerminal,
  domain
}) => {
  const refButton = useRef();
  const applePayConfig = config.expressCheckout?.applePay || {};

  useEffect(() => {
    if (refButton.current) {
      refButton.current.addEventListener("click", () => {
        if (!token || !domain) return;
        const payabliConfig = { ...config, vTerminal };
        const wallet = new Wallet(payabliConfig, token);
        wallet.applePayInit(domain);
      });
    }
  }, [refButton, token, config, vTerminal, domain]);

  return (
    <>
      <Helmet>
        <style type="text/css">{`
            apple-pay-button#btn-apple-pay-${config.randomId} {
                --apple-pay-button-width: 100%;
                --apple-pay-button-height: ${
                  config?.expressCheckout?.appearance?.buttonHeight || 44
                }px;
                --apple-pay-button-border-radius: ${
                  config?.expressCheckout?.appearance?.buttonBorderRadius || 4
                }px;
                --apple-pay-button-padding: ${
                  config?.expressCheckout?.appearance?.padding?.x || 8
                }px ${config?.expressCheckout?.appearance?.padding?.y || 11}px;
                --apple-pay-button-box-sizing: border-box;
            }
         `}</style>
      </Helmet>
      <apple-pay-button
        id={`btn-apple-pay-${config.randomId}`}
        ref={refButton}
        buttonstyle={applePayConfig?.buttonStyle || "black"}
        type={applePayConfig?.buttonType || "plain"}
        locale={applePayConfig?.language || "en-US"}
      />
    </>
  );
};

class Wallet {
  constructor(payabliConfig, temporaryTokenFromApi) {
    const { expressCheckout = {} } = payabliConfig;

    this.urlApi = process.env.REACT_APP_URL_API
    this.payabliConfig = payabliConfig;
    this.temporaryTokenFromApi = temporaryTokenFromApi;

    const amount = Number(expressCheckout.amount).toFixed(2);
    const fee = Number(expressCheckout.fee).toFixed(2);
    const totalAmount = Number(amount) + Number(fee);

    this.applePayRequestPayload = {
      countryCode: "US",
      currencyCode: expressCheckout.currency || "USD",
      merchantCapabilities: ["supports3DS"],
      supportedNetworks: expressCheckout.supportedNetworks || [],
      requiredBillingContactFields: ["postalAddress", "name"],
      requiredShippingContactFields:
        expressCheckout?.requiredShippingContactFields
          ? ["postalAddress", "name", "phoneticName", "phone", "email"]
          : [],
      lineItems: [
        {
          label: "Subtotal",
          amount: amount,
        },
        {
          label: "Service Fee",
          amount: fee,
        },
      ],
      total: {
        label: expressCheckout.label || "Confirm Payment",
        type: "final",
        amount: totalAmount,
      },
    };
    this.paymentRequest = {
      entryPoint: payabliConfig.entryPoint,
      paymentMethod: {
        method: "wallet",
        walletType: "apple_pay",
        walletToken:
          "<base64 string of event.payment received in onpaymentauthorized>",
      },
      paymentDetails: {
        totalAmount: totalAmount,
        serviceFee: fee,
      },
      source: "express_checkout_apple_pay",
      customerData: {
        firstName: payabliConfig?.customerData?.firstName,
        lastName: payabliConfig?.customerData?.lastName,
        customerNumber: payabliConfig?.customerData?.customerNumber,
        billingEmail: payabliConfig?.customerData?.billingEmail,
      },
    };
  }

  // Initialize Apple Pay
  async applePayInit(domain) {
    try {
      if (!canUseApplePay()) {
        throw new Error("Apple Pay is not available");
      }
      const session = new window.ApplePaySession(
        3,
        this.applePayRequestPayload
      );
      session.onvalidatemerchant = async () => {
        const response = await fetch(`${this.urlApi}Wallet/applepay/session`, {
          method: "POST",
          body: JSON.stringify({
            entry: this.payabliConfig.entryPoint,
            domainName: domain,
            displayName: "",
            validationURL: "",
          }),
          headers: {
            "Content-Type": "application/json",
            requestToken: this.temporaryTokenFromApi,
          },
        });

        const data = await response.json();
        session.completeMerchantValidation(data);
      };

      session.onpaymentauthorized = async (event) => {
        let walletToken = btoa(JSON.stringify(event.payment));
        this.paymentRequest.paymentMethod.walletToken = walletToken;
        const payload = {
          ...this.paymentRequest,
          customerData: {
            ...this.paymentRequest.customerData,
            billingAddress1: event.payment.billingContact.addressLines[0] || "",
            billingAddress2: event.payment.billingContact.addressLines[1] || "",
            billingCity: event.payment.billingContact.locality || "",
            billingState: event.payment.billingContact.administrativeArea || "",
            billingZip: event.payment.billingContact.postalCode || "",
            billingCountry: event.payment.billingContact.country || "",
          },
        };
        const response = await fetch(`${this.urlApi}MoneyIn/getpaid`, {
          method: "POST",
          body: JSON.stringify(payload),
          headers: {
            "Content-Type": "application/json",
            requestToken: this.temporaryTokenFromApi,
          },
        });

        const data = await response.json();
        let result;
        if (data.responseText === "Success") {
          result = {
            status: session.STATUS_SUCCESS,
          };
          data.responseData.billingContact = event.payment.billingContact;
          data.responseData.shippingContact = event.payment.shippingContact;
          window.parent.postMessage(
            {
              event:
                "callback-payabli-function-expresscheckout-success" +
                this.payabliConfig.randomId,
              data: {
                data,
                paymentMethod: "apple_pay",
              },
            },
            "*"
          );
        } else {
          result = {
            status: session.STATUS_FAILURE,
          };
          window.parent.postMessage(
            {
              event:
                "callback-payabli-function-expresscheckout-error" +
                this.payabliConfig.randomId,
              data: {
                error: data,
                paymentMethod: "apple_pay",
              },
            },
            "*"
          );
          console.error(data)
        }

        session.completePayment(result);
      };

      session.oncancel = (event) => {
        const data = { ...event };
        window.parent.postMessage(
          {
            event:
              "callback-payabli-function-expresscheckout-cancel" +
              this.payabliConfig.randomId,
            data: {
              data,
              paymentMethod: "apple_pay",
            },
          },
          "*"
        );
      };

      session.begin();
    } catch (e) {
      window.parent.postMessage(
        {
          event:
            "callback-payabli-function-expresscheckout-error" +
            this.payabliConfig.randomId,
          data: {
            error: e,
            paymentMethod: "apple_pay",
          },
        },
        "*"
      );
      console.error(e);
    }
  }
}

// Check if Apple Pay is available
export function canUseApplePay() {
  return (
    window.ApplePaySession &&
    window.ApplePaySession.canMakePayments() &&
    window.ApplePaySession.supportsVersion(3)
  );
}
