import axios from 'axios';

axios.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    console.error(error)
    return Promise.reject(error);
  }
);