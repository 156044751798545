import React from 'react';
import { inject } from 'mobx-react';
import {  Modal, Accordion, Card} from 'react-bootstrap';
import { BiChevronDown, BiChevronRight} from 'react-icons/bi';
import { toJS } from "mobx";
import {IMaskInput} from 'react-imask';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';

@inject('global','customer', 'vTerminal')
class EditCustomer extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            modalIsOpen: false,
            
            id: "",
            customerNumber: "",
            company: "",
            firstName: "",
            lastName: "",
            email: "",
            phone: "",
            customFields: [],
            identifiers: [],
            identifiersSelected: [],
            
            billingAddressValue: "",
            billingAddress: "",
            billingAddress2: "",
            billingCity: "",
            billingState: "",
            billingZip: "",
            billingCountry: "",

            shippingAddress: "",
            shippingAddress2: "",
            shippingCity: "",
            shippingState: "",
            shippingZip: "",
            shippingCountry: "",

            billingSameShipping: true,

            formErrors: {},

            accordionBillingDetailsOpen : false,
            accordionCustomFieldsOpen : false,
            accordionIdentifiersOpen : false
        };
        this.closeModal = this.closeModal.bind(this);
        this.openModal = this.openModal.bind(this);
        this.addCustomer = this.addCustomer.bind(this);
        this.customFieldsKeyHandleTextChange = this.customFieldsKeyHandleTextChange.bind(this);
        this.customFieldsValueHandleTextChange = this.customFieldsValueHandleTextChange.bind(this);
        this.handleTextChange = this.handleTextChange.bind(this);
        this.addCustomField = this.addCustomField.bind(this);
        this.removeCustomField = this.removeCustomField.bind(this);
        this.validateFields = this.validateFields.bind(this);
        this.handleTextChangeMask = this.handleTextChangeMask.bind(this);
        this.identifierHandleTextChange = this.identifierHandleTextChange.bind(this);
        this.billingSameShipping = this.billingSameShipping.bind(this);
        this.handleGoogleAutocompleteBillingAddress = this.handleGoogleAutocompleteBillingAddress.bind(this);
        this.handleGoogleAutocompleteShippingAddress = this.handleGoogleAutocompleteShippingAddress.bind(this);
        this.handleSelectIdentifier = this.handleSelectIdentifier.bind(this);
        this.isInIdentifiersSelected = this.isInIdentifiersSelected.bind(this);
        this.collapseAccordion = this.collapseAccordion.bind(this);
        this.accordionBillingDetails = React.createRef();
        this.baseState = this.state;
    }

    resetState = () => {
        this.setState(this.baseState)
    }

    componentDidMount(){
        let command = this.props.command;
       
        if(command === "add"){
            this.openModal();
        }
  
        this.props.global.setLoading(true);
        this.props.customer.getIdentifiers(this.props.pconfig.token).then(res => {
            this.setState({identifiers: res.data})
            this.props.global.setLoading(false);
        })
        .catch(error => {
            console.error(error)
            this.props.global.setLoading(false);
            //let errorMessage = error.response.data.responseText ? error.response.data.responseText : "Something is Wrong!";
        });

    }

    collapseAccordion(accordion){
        this.setState({[accordion]:!this.state[accordion]});
    }

    handleSelectIdentifier(e, record){
        let identifiersSelected = this.state.identifiersSelected;
        
        if(e.target.checked){
            identifiersSelected.push(record)
        }
        else{
            identifiersSelected.splice(identifiersSelected.indexOf(record), 1); 
        }

        this.setState({identifiersSelected:identifiersSelected});
    }

    handleGoogleAutocompleteBillingAddress(e){
        if(e.value){
            this.setState({ 
                billingAddressValue : e.value.description
             }, function(){
                if(e.value.types[0] === 'premise' && e.value.types[1] === "geocode"){
                    this.setState({
                        billingAddress : e.value.description ?  e.value.description : '',
                        billingCity : e.value.terms[2] ?  e.value.terms[2].value : '',
                        billingState : e.value.terms[3] ?  e.value.terms[3].value : '',
                        billingZip : e.value.terms[4] ?  e.value.terms[4].value : '',
                        billingCountry : e.value.terms[5] ?  e.value.terms[5].value : ''
                     });
                }

                if(e.value.types[0] === 'street_address' && e.value.types[1] === "geocode"){
                    this.setState({ 
                        billingAddress : e.value.description ?  e.value.description : '',
                        billingCity : e.value.terms[1] ?  e.value.terms[1].value : '',
                        billingState : e.value.terms[2] ?  e.value.terms[2].value : '',
                        billingZip : e.value.terms[3] ?  e.value.terms[3].value : '',
                        billingCountry : e.value.terms[4] ?  e.value.terms[4].value : ''
                     });
                }

                if(e.value.types[0] === 'route' && e.value.types[1] === "geocode"){
                    this.setState({ 
                        billingAddress : e.value.description ?  e.value.description : '',
                        billingCity : e.value.terms[2] ?  e.value.terms[2].value : '',
                        billingState : e.value.terms[3] ?  e.value.terms[3].value : '',
                        billingZip : e.value.terms[4] ?  e.value.terms[4].value : '',
                        billingCountry : e.value.terms[5] ?  e.value.terms[5].value : ''
                     });
                }
                
             });
        }
    }

    handleGoogleAutocompleteShippingAddress(e){

        if(e.value.types[0] === 'postal_code'){
            this.setState({ 
                shippingAddress : e.value ?  e.value.description : '',
                shippingCity : e.value.terms[0] ?  e.value.terms[0].value : '',
                shippingState : e.value.terms[1] ?  e.value.terms[1].value : '',
                shippingZip : e.value.terms[2] ?  e.value.terms[2].value : '',
                shippingCountry : e.value.terms[3] ?  e.value.terms[3].value : ''
             });
        }else if(e.value.types[0] === 'locality'){
            this.setState({ 
                shippingAddress : e.value ?  e.value.description : '',
                shippingCity : e.value.terms[0] ?  e.value.terms[0].value : '',
                shippingState : e.value.terms[1] ?  e.value.terms[1].value : '',
                shippingCountry : e.value.terms[2] ?  e.value.terms[2].value : ''
             });
        }else{
            this.setState({ 
                shippingAddress : e.value ?  e.value.description : '',
                shippingCity : e.value.terms[2] ?  e.value.terms[2].value : '',
                shippingState : e.value.terms[3] ?  e.value.terms[3].value : '',
                shippingCountry : e.value.terms[4] ?  e.value.terms[4].value : ''
             });
        }

        
    }

    addCustomField(e){
        if(e){
            e.preventDefault();
        }
       
        let customFields = this.state.customFields;
        customFields.push({key:"", value: ""});
        this.setState({ customFields: customFields });
    }
    
    billingSameShipping(e){
        this.setState({ billingSameShipping: e.target.checked });
    }

    handleTextChange(e){
        this.setState({ [e.target.id]: e.target.value });
    }
   
    customFieldsKeyHandleTextChange(e, i){
        let customFields = this.state.customFields;
        customFields[i].key = e.target.value;
        this.setState({ customFields: customFields });
    }

    customFieldsValueHandleTextChange(e, i){
        let customFields = this.state.customFields;
        customFields[i].value = e.target.value;
        this.setState({ customFields: customFields });
    }
   
    identifierHandleTextChange(e){
        let identifiers = this.state.identifiers;
        identifiers[e.target.id] = e.target.value;
        this.setState({ identifiers: identifiers });
    }
    
    handleTextChangeMask(e, value){
        this.setState({ [e]: value });
    }

    closeModal(){
        this.setState({ modalIsOpen: false });
    }

    openModal(e){
        if(e){
            e.preventDefault();
        }
        let customFields = [];
        let customFieldsApi = this.props.customerToUpdate && this.props.customerToUpdate.AdditionalFields ? toJS(this.props.customerToUpdate.AdditionalFields) : [];
        /*customFieldsApi.forEach(function (item) {
            customFields.push({key:item.key, value: item.value});
        });*/

        for (var [key, value] of Object.entries(customFieldsApi)) {
            customFields.push({key:key, value: value});
        }

        let defaultfirstName = this.props.customerToUpdate && this.props.customerToUpdate.Firstname ? this.props.customerToUpdate.Firstname : "";
        let defaultlastName = this.props.customerToUpdate && this.props.customerToUpdate.Lastname ? this.props.customerToUpdate.Lastname : "";

        if(this.props.defaultName){
            let defaultNameParts = this.props.defaultName.split(" ")
            defaultfirstName = defaultNameParts[0] ? defaultNameParts[0] : defaultfirstName;
            defaultlastName = defaultNameParts[1] ? defaultNameParts[1] : defaultlastName;
        }

        let billingSameShipping = true;
        if(this.props.customerToUpdate && (this.props.customerToUpdate.Address !== this.props.customerToUpdate.ShippingAddress || this.props.customerToUpdate.Address1 !== this.props.customerToUpdate.ShippingAddress1)){
            billingSameShipping = false;
        }



         //if(this.props.customerToUpdate && 

        this.setState({
            id: this.props.customerToUpdate && this.props.customerToUpdate.customerId ? this.props.customerToUpdate.customerId : "",
            customerNumber: this.props.customerToUpdate && this.props.customerToUpdate.customerNumber ? this.props.customerToUpdate.customerNumber : "",
            company: this.props.customerToUpdate && this.props.customerToUpdate.Company ? this.props.customerToUpdate.Company : "",
            firstName: defaultfirstName,
            lastName: defaultlastName,
            email: this.props.customerToUpdate && this.props.customerToUpdate.Email ? this.props.customerToUpdate.Email : "",
            phone: this.props.customerToUpdate && this.props.customerToUpdate.Phone ? this.props.customerToUpdate.Phone : "",
            customFields: customFields,
            identifiersSelected: this.props.customerToUpdate && this.props.customerToUpdate.IdentifierFields ? toJS(this.props.customerToUpdate.IdentifierFields) : [],
            modalIsOpen: true,

            billingAddress: this.props.customerToUpdate && this.props.customerToUpdate.Address ? this.props.customerToUpdate.Address : "",
            billingAddress2: this.props.customerToUpdate && this.props.customerToUpdate.Address1 ? this.props.customerToUpdate.Address1 : "",
            billingCity: this.props.customerToUpdate && this.props.customerToUpdate.City ? this.props.customerToUpdate.City : "",
            billingState: this.props.customerToUpdate && this.props.customerToUpdate.State ? this.props.customerToUpdate.State : "",
            billingZip: this.props.customerToUpdate && this.props.customerToUpdate.Zip ? this.props.customerToUpdate.Zip : "",
            billingCountry: this.props.customerToUpdate && this.props.customerToUpdate.Country ? this.props.customerToUpdate.Country : "",

            shippingAddress: this.props.customerToUpdate && this.props.customerToUpdate.ShippingAddress ? this.props.customerToUpdate.ShippingAddress : "",
            shippingAddress2: this.props.customerToUpdate && this.props.customerToUpdate.ShippingAddress1 ? this.props.customerToUpdate.ShippingAddress1 : "",
            shippingCity: this.props.customerToUpdate && this.props.customerToUpdate.ShippingCity ? this.props.customerToUpdate.ShippingCity : "",
            shippingState: this.props.customerToUpdate && this.props.customerToUpdate.ShippingState ? this.props.customerToUpdate.ShippingState : "",
            shippingZip: this.props.customerToUpdate && this.props.customerToUpdate.ShippingZip ? this.props.customerToUpdate.ShippingZip : "",
            shippingCountry: this.props.customerToUpdate && this.props.customerToUpdate.ShippingCountry ? this.props.customerToUpdate.ShippingCountry : "",

            billingSameShipping: billingSameShipping
        });
    }

    addCustomer(){
        let errors = this.validateFields();
        this.setState({ formErrors: errors }, function(){
            /*if(!this.state.accordionCustomFieldsOpen && this.hasCustomFieldsErrors()){
                this.accordionCustomFields.current.click();
            }*/
            
            if(!this.state.accordionBillingDetailsOpen && this.state.formErrors.billingAddress){
                this.accordionBillingDetails.current.click();
            }

            if(Object.keys(errors).length === 0){
                let customer ={
                    id: this.state.id,
                    company: this.state.company,
                    customerNumber: this.state.customerNumber,
                    firstname: this.state.firstName,
                    lastname: this.state.lastName,
                    email: this.state.email,
                    phone: this.state.phone,
                    additionalFields: this.state.customFields,
                    identifierFields: this.state.identifiersSelected,

                    address: this.state.billingAddress,
                    address1: this.state.billingAddress2,
                    city: this.state.billingCity,
                    state: this.state.billingState,
                    zip: this.state.billingZip,
                    country: this.state.billingCountry,

                    shippingAddress: this.state.billingSameShipping ? this.state.billingAddress : this.state.shippingAddress,
                    shippingAddress1: this.state.billingSameShipping ? this.state.billingAddress2 : this.state.shippingAddress2,
                    shippingCity: this.state.billingSameShipping ? this.state.billingCity : this.state.shippingCity,
                    shippingState: this.state.billingSameShipping ? this.state.billingState : this.state.shippingState,
                    shippingZip: this.state.billingSameShipping ? this.state.billingZip : this.state.shippingZip,
                    shippingCountry: this.state.billingSameShipping ? this.state.billingCountry : this.state.shippingCountry
                    
                }


                if((this.state.customerNumber === '' || this.state.customerNumber === null) && this.state.identifiersSelected.length === 0){
                    customer.identifierFields = ["email"];
                }

                
              
                this.props.global.setLoading(true);
                
                this.props.customer.createCustomer(customer, this.props.vTerminal.config, this.props.vTerminal.token)
                .then(result => {

                    if(this.props.rewriteSelectedWithResponse && this.props.rewriteSelectedWithResponse === true){
                        if(result.data && result.data.responseData){
                            this.props.vTerminal.selectCustomerObjectInSearch(result.data.responseData);
                        }
                        
                    }
                    else{
                        this.props.vTerminal.selectCustomerObjectInSearch(customer);
                    }

                    
                    if((this.props.vTerminal.getCustomerSelected.StoredMethods === undefined) || (this.props.vTerminal.getCustomerSelected.StoredMethods?.length === 0)){
                        this.props.vTerminal.setPaymentMethod('card');
                        this.props.vTerminal.setDefaultPaymentMethodActiveKey("0");
                    }

                    if(this.props.functionCallbackNew){
                        this.props.functionCallbackNew();
                    }

                    // calling parent functionCallback after saving
                    if(this.props.functionCallback){
                        this.props.functionCallback();
                    }

                    this.closeModal();
                    this.props.global.setLoading(false);
                    this.resetState();
                })
                .catch(error => {
                    console.error(error)
                    this.props.global.setLoading(false);
                    //let errorMessage = error.response.data.responseText ? error.response.data.responseText : "Something is Wrong!";
                }); 
            }
        });
        
    }

    isInIdentifiersSelected(){
        
    }

    removeCustomField(i){
        let customFields = this.state.customFields;
        customFields.splice(i, 1);
        this.setState({ customFields: customFields });
    }

    validateFields(){
        let validators = this.props.global.validators;
        let errors = {};
        if(validators.isMaxLength(250, this.state.company))
        {
            errors['company']= true;
        }

        if(validators.isMaxLength(250, this.state.customerNumber))
        {
            errors['customerNumber']= true;
        }

        if(validators.isEmpty(this.state.firstName) || validators.isMaxLength(250, this.state.firstName))
        {
            errors['firstName']= true;
        }

        if(validators.isEmpty(this.state.lastName) || validators.isMaxLength(250, this.state.lastName))
        {
            errors['lastName']= true;
        }

        if(validators.isEmpty(this.state.email) || validators.isMaxLength(250, this.state.email) || validators.stringValidator('email',this.state.email))
        {
            errors['email']= true;
        }

        if(validators.stringValidator('phone', this.state.phone))
        {
            errors['phone']= true;
        }
        
        if(validators.isMaxLength(250, this.state.billingAddress))
        {
            errors['billingAddress']= true;
        }

        this.state.customFields.forEach(function (item, index) {
            if(validators.isMaxLength(250, item.key)){
                errors['customFieldsKey'+index]= true;
            }

            if(validators.isMaxLength(250, item.value)){
                errors['customFieldsValue'+index]= true;
            }
        });

        return errors;

        
    }

    render() {

        let reactObj = this;
        return (
            <>
                <Modal show={this.state.modalIsOpen} onHide={(e) => this.closeModal()}  size="md"  aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Body className="popover-body">

                <div className="row mb-3">
                    <div className="col"><h6>{this.props.title ? this.props.title : "Edit customer"}</h6></div>
                </div>

                <div className="row">
                   <div className="col-12 mb-3">
                       <span className="mb-2" style={{display: "block"}}>Account Information</span> 
                       <div className="form-floating">
                            <input className={this.state.formErrors.company ? "form-control input-error" : "form-control"} onChange={(e) => this.handleTextChange(e)} placeholder="Business Name" id="company" value={ this.state.company } />
                            <label htmlFor="company">Business Name</label>
                        </div>
                   </div>
                   <div className="col-12 mb-3">
                        <div className="form-floating">
                            <IMaskInput
                                mask={this.props.global.maskValidator('alphanumeric')}
                                name="customerNumber"
                                value={ this.state.customerNumber  }
                                unmask={true}
                                onAccept={
                                    (value, mask) => this.handleTextChangeMask('customerNumber', value)
                                }
                                placeholder="Customer Number" 
                                className={this.state.formErrors.customerNumber ? "form-control input-error" : "form-control"}
                                id="customerNumber"
                            />
                            <label htmlFor="firstName">Customer Number</label>
                        </div>
                   </div>
                   <div className="col-6 mb-3">
                        <div className="form-floating">
                            <input className={this.state.formErrors.firstName ? "form-control input-error" : "form-control"} onChange={(e) => this.handleTextChange(e)} placeholder="First Name" id="firstName" value={ this.state.firstName }/>
                            <label htmlFor="firstName">First Name</label>
                        </div>
                   </div>
                   <div className="col-6 mb-3">
                        <div className="form-floating">
                            <input className={this.state.formErrors.lastName ? "form-control input-error" : "form-control"} onChange={(e) => this.handleTextChange(e)} placeholder="Last Name" id="lastName" value={ this.state.lastName } />
                            <label htmlFor="lastName">Last Name</label>
                        </div>
                   </div>
                   <div className="col-6 mb-3">
                        <div className="form-floating">
                            <IMaskInput
                                mask={this.props.global.maskValidator('email')}
                                name="email"
                                value={ this.state.email }
                                unmask={true}
                                onAccept={
                                    (value, mask) => this.handleTextChangeMask('email', value)
                                }
                                placeholder="Email"
                                className={this.state.formErrors.email ? "form-control input-error" : "form-control"}
                                id="email"
                            />
                            <label htmlFor="email">Email</label>
                        </div>
                   </div>
                   <div className="col-6 mb-3">
                        <div className="form-floating">
                            <IMaskInput
                                mask={this.props.global.maskValidator('phone')}
                                name="phone"
                                value={ this.state.phone }
                                unmask={true}
                                onAccept={
                                    (value, mask) => this.handleTextChangeMask('phone', value)
                                }
                                placeholder="Primary Phone #"
                                className={this.state.formErrors.phone ? "form-control input-error" : "form-control"}
                                id="phone"
                            />
                            <label htmlFor="phone">Primary Phone #</label>
                        </div>
                   </div>
                </div>
                <Accordion>
                <Card className="card-no-border mb-2">
                    <Accordion.Toggle as={Card.Header} eventKey="0" className="mb-2" ref={this.accordionBillingDetails} onClick={(e)=>this.collapseAccordion("accordionBillingDetailsOpen")}>
                        {this.state.accordionBillingDetailsOpen ? <BiChevronDown/> : <BiChevronRight/>} Billing Details
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="0">
                    <Card.Body>
                    <div className="col-12 mb-3">
                        <div  className={this.state.formErrors.billingAddress ? "input-error" : ""}>
                        <GooglePlacesAutocomplete
                            apiKey={process.env.REACT_APP_GOOGLE_PLACES_KEY}
                            autocompletionRequest={{
                                componentRestrictions: {
                                    country: ['us']
                                }
                            }}
                            apiOptions={{ language: 'en' }}
                            selectProps={{
                                placeholder: this.state.billingAddress ? this.state.billingAddress : 'Billing Address',
                                onChange: (e) => this.handleGoogleAutocompleteBillingAddress(e),
                                autocomplete: "off",
                                className: "gautocomplete",
                                defaultInputValue: this.state.billingAddress ? this.state.billingAddress : ''
                            }}
                        />
                    </div>
                    </div>
                    <div className="col-12 mb-3">
                        <div className="form-floating">
                            <input id="billingAddress2" value={ this.state.billingAddress2 } onChange={(e) => this.handleTextChange(e)} className={this.state.formErrors.billingAddress2 ? "form-control input-error" : "form-control"} placeholder="Billing Address 2" />
                            <label htmlFor="billingAddress2">Billing Address 2 (ex: apt #, suite #, building #, etc )</label>
                        </div>
                   </div>
                   
                    <div className="col-12 mb-3">
                        <div className="icheck-primary">
                        <input defaultChecked={this.state.billingSameShipping} type="checkbox" id="billingAddressSameShipping" onClick={(e) => this.billingSameShipping(e)} />
                        <label htmlFor="billingAddressSameShipping">Shipping address is same as billing</label>
                        </div>
                    </div>
                    {!this.state.billingSameShipping &&
                        <>
                        <div className="col-12 mb-3">
                        
                        <GooglePlacesAutocomplete
                            apiKey={process.env.REACT_APP_GOOGLE_PLACES_KEY}
                            autocompletionRequest={{
                                componentRestrictions: {
                                    country: ['us']
                                }
                            }}
                            apiOptions={{ language: 'en' }}
                            selectProps={{
                                placeholder: this.state.shippingAddress ? this.state.shippingAddress : 'Shipping Address',
                                onChange: (e) => this.handleGoogleAutocompleteShippingAddress(e),
                                autocomplete: "off",
                                className: "gautocomplete"
                            }}
                        />
                        </div>
                        <div className="col-12 mb-3">
                            <div className="form-floating">
                                <input id="shippingAddress2" value={ this.state.shippingAddress2 } onChange={(e) => this.handleTextChange(e)} className={this.state.formErrors.shippingAddress2 ? "form-control input-error" : "form-control"} placeholder="Shipping Address 2" />
                                <label htmlFor="shippingAddress2">Shipping Address 2 (ex: apt #, suite #, building #, etc )</label>
                            </div>
                        </div>
                        </>
                    }
                    </Card.Body>
                    </Accordion.Collapse>
                </Card>
                </Accordion>

                <Accordion>
                <Card className="card-no-border mb-2">
                    <Accordion.Toggle as={Card.Header} eventKey="0" className="mb-2" onClick={(e)=>this.collapseAccordion("accordionCustomFieldsOpen")}>
                        {this.state.accordionCustomFieldsOpen ? <BiChevronDown/> : <BiChevronRight/>} Custom Fields
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="0">
                    <Card.Body>
                      
                        { this.state.customFields.map((record, i) => (
                        <div key={i} className="row">
                            <div className="col-5 mb-3">
                                <div className="form-floating">
                                    <input className={this.state.formErrors['customFieldsKey'+i] ? "form-control input-error" : "form-control"} onChange={(e) => this.customFieldsKeyHandleTextChange(e, i)} id={"customFieldsKey"+i} placeholder={"Field Name"} value={record.key} />
                                    <label htmlFor={"customFieldsKey"+i}>{"Field Name"}</label>
                                </div>
                            </div>
                            <div className="col-5 mb-3">
                                <div className="form-floating">
                                    <input className={this.state.formErrors['customFieldsValue'+i] ? "form-control input-error" : "form-control"} onChange={(e) => this.customFieldsValueHandleTextChange(e, i)} id={"customFieldsValue"+i} placeholder={"Value"} value={record.value} />
                                    <label htmlFor={"customFieldsValue"+i}>{"Value"}</label>
                                </div>
                            </div>
                            <div className="col-2 text-center mb-3">
                                <div className="category-icon mt-2">
                                <button className="remove-icon-btn" type="button" onClick={(e) => this.removeCustomField(i)}>&nbsp;</button>
                                </div>
                            </div>
                        </div>
                        ))
                        }

                    <div className="row mb-3">
                        <div className="col">
                            <a onClick={(e) => this.addCustomField(e)} href="/">Add custom field</a>
                        </div>
                    </div>
                       
                        
                    </Card.Body>
                    </Accordion.Collapse>
                </Card>
                </Accordion>
                

                <Accordion>
                <Card className="card-no-border mb-4">
                    <Accordion.Toggle as={Card.Header} eventKey="0" className="mb-2" onClick={(e)=>this.collapseAccordion("accordionIdentifiersOpen")}>
                        {this.state.accordionIdentifiersOpen ? <BiChevronDown/> : <BiChevronRight/>} Identifiers
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="0">
                    <Card.Body>
                      {this.state.identifiers &&
                        <div className="row">
                        { this.state.identifiers.map((record, i) => (
                            <div key={i} className="col-4">
                    
                                 <div className="icheck-primary">
                                <input defaultChecked={reactObj.state.identifiersSelected.indexOf(record) > -1} type="checkbox" id={"identifier"+i} onClick={(e) => this.handleSelectIdentifier(e, record)} />
                                <label className="small-small" htmlFor={"identifier"+i}>{record}</label>
                                </div>
                            </div>
                        ))}
                        </div>
                        }


                    {this.state.customFields &&
                        <div className="row">
                        { this.state.customFields.map((record, i) => (
                            <div key={i} className="col-4">
                                 <div className="icheck-primary">
                                <input defaultChecked={reactObj.state.identifiersSelected.indexOf(record.key) > -1} type="checkbox" id={"customFieldIdentifier"+i} onClick={(e) => this.handleSelectIdentifier(e, record.key)} />
                                <label className="small-small" htmlFor={"customFieldIdentifier"+i}>{record.key}</label>
                                </div>
                            </div>
                        ))}
                        </div>
                        }

                       
                        
                    </Card.Body>
                    </Accordion.Collapse>
                </Card>
                </Accordion>

                <div className="row">
                    <div className="col-sm-12 col-md-6 mb-3">
                        <button className="btn full-w btn-light" type="button" onClick={() => this.closeModal() }>Cancel</button>
                    </div>
                    <div className="col-sm-12 col-md-6">
                    <button className="btn full-w btn-primary" type="button" onClick={() => this.addCustomer()}>{this.props.label ? this.props.label : "Edit customer"}</button>   
                    </div>
                </div>
                </Modal.Body>
                </Modal>
                <a style={ this.props.style ? this.props.style : {}} href="/" className={this.props.className ? this.props.className : "dropdown-item"} onClick={(e) => this.openModal(e)}>{this.props.label ? this.props.label : "Edit customer"}</a>
            </>
        )
    }
}

export { EditCustomer };